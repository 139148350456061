import React from "react";
import axios from "axios";
import * as XLSX from "xlsx";

const ExportExcel = ({ user_id, user_name }) => {
  // Destructure username from props
  const handleExport = async () => {
    console.log("user id:", user_id);
    console.log("user name:", user_name);
    try {
      const response = await axios.get(
        `https://rfidpartnership.com/api/fulldocuments/?user_id=${user_id}`
      );
      const data = response.data.documents; // Extracting the 'documents' array from response

      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      let currentDate = `${day}-${month}-${year}`;
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${user_name}-${currentDate}.xlsx`);

      console.log("Data exported successfully!");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <div>
      <i className="bx bx-download" onClick={handleExport}></i>
    </div>
  );
};

export default ExportExcel;
