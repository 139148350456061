import React, { useState, useEffect } from "react";

function Approval({ user }) {
  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Approval</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3>Approval</h3>
              <div className="header-container">
                ...
              </div>
            </div>
            <div className="ApprovalDisplay">
              Approval Display
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Approval;