import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const OutgoingFetcher = ({
  onFetchTargetItems,
  onRowClick,
  currentItems = [],
  searchQuery = "",
  user,
  branch,
}) => {
  const [data, setData] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // Fetch documents based on user or branch
    const fetchUrl =
      user.type === "cabang" || !branch
        ? `https://rfidpartnership.com/api/documents/?username=${user.username}`
        : `https://rfidpartnership.com/api/documents/?username=${branch.username}`;

    fetch(fetchUrl)
      .then((response) => response.json())
      .then((data) => {
        setData(data.documents);
        if (onFetchTargetItems) {
          onFetchTargetItems(data.documents.map((doc) => doc.rfid_tag_id));
        }
      })
      .catch((err) => console.error(err));
  }, [onFetchTargetItems, user, branch]);

  // Handle row click
  const handleRowClickInternal = (doc) => {
    // Toggle selection of the clicked document
    if (selectedDocuments.includes(doc.rfid_tag_id)) {
      setSelectedDocuments(
        selectedDocuments.filter((item) => item !== doc.rfid_tag_id)
      );
    } else {
      setSelectedDocuments([...selectedDocuments, doc.rfid_tag_id]);
    }

    // Pass the clicked document to parent component's onRowClick
    if (onRowClick) {
      onRowClick(doc);
    }
  };

  // Normalize the currentItems for comparison
  const normalizedCurrentItems = currentItems.map((item) =>
    item.trim().toLowerCase()
  );

  // Filter data based on searchQuery
  const filteredData = data.filter(
    (doc) =>
      doc.rfid_tag_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.nama_dokumen.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Console log selectedDocuments whenever it changes
  useEffect(() => {
    console.log("Selected Documents:", selectedDocuments);
  }, [selectedDocuments]);

  return (
    <div>
      <table id="databaseTable">
        <thead>
          <tr>
            <th colSpan="3">
              <div className="uppercase">
                {branch && branch.branch_name
                  ? branch.branch_name
                  : user.branch_name}
              </div>
            </th>
          </tr>
          <tr>
            <th colSpan="3">Total Dokumen: {filteredData.length}</th>
          </tr>
          <tr>
            <th>RFID</th>
            <th>Nama Dokumen</th>
          </tr>
        </thead>
        <tbody id="databaseTableBody">
          {filteredData.map((doc, index) => (
            <tr
              key={index}
              style={{
                color:
                  location.pathname === "/scan-room" &&
                  !normalizedCurrentItems.includes(
                    doc.rfid_tag_id.trim().toLowerCase()
                  )
                    ? "#d32f2f"
                    : selectedDocuments.includes(doc.rfid_tag_id)
                    ? "green"
                    : "",
                backgroundColor:
                  location.pathname === "/scan-room" &&
                  !normalizedCurrentItems.includes(
                    doc.rfid_tag_id.trim().toLowerCase()
                  )
                    ? "#f2dede"
                    : selectedDocuments.includes(doc.rfid_tag_id)
                    ? "#dff0d8"
                    : "",
              }}
              onClick={() => handleRowClickInternal(doc)}
            >
              <td>{doc.rfid_tag_id}</td>
              <td>{doc.nama_dokumen}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OutgoingFetcher;
