import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const RequestFetcher = ({
  onFetchTargetItems,
  searchQuery = "",
  onRowClick,
  user,
}) => {
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    console.log("User in Request Fetcher:", user);
    fetch(
      `https://rfidpartnership.com/api/request/?username=${user.username}&type=${user.type}`
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.documents);
        if (onFetchTargetItems) {
          onFetchTargetItems(data.documents.map((doc) => doc.request_id));
        }
      })
      .catch((err) => console.error(err));
  }, [onFetchTargetItems]);

  if (!data.length) return "No request available.";

  // Filter data based on searchQuery
  const filteredData = data.filter((doc) =>
    doc.request_id.toString().toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to format the created_at date
  const formatDate = (dateString) => {
    return dateString.replace("T", " ").replace("Z", "").split(".")[0];
  };

  return (
    <div>
      <table id="databaseTable">
        <thead>
          <tr>
            <th>Total Request: {filteredData.length}</th>
          </tr>
          <tr>
            <th>Request ID</th>
            <th>From</th>
            <th>Tanggal Dibuat</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody id="databaseTableBody">
          {filteredData.map((doc, index) => (
            <tr key={index} onClick={() => onRowClick(doc)}>
              <td>{doc.request_id}</td>
              <td className="uppercase">{doc.from_name}</td>
              <td>{formatDate(doc.created_at)}</td>
              <td>{doc.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestFetcher;
