import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf"; // Import jsPDF
import "jspdf-autotable";

// Function to format date
function formatIndonesianDate(date) {
  const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const dayName = days[date.getDay()];
  const day = date.getDate();
  const monthName = months[date.getMonth()];
  const year = date.getFullYear();

  return `${dayName}, ${day} ${monthName} ${year}`;
}

// Function to format date
function formatIndonesianDateWithoutDay(date) {
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const day = date.getDate();
  const monthName = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${monthName} ${year}`;
}

const DBFetcherUser = ({
  onFetchTargetItems,
  currentItems = [],
  searchQuery = "",
  onRowClick,
  user,
  branch,
  selectedRequest,
}) => {
  const [data, setData] = useState([]);
  const [redHighlightedDocs, setRedHighlightedDocs] = useState([]);
  const location = useLocation();

  useEffect(() => {
    let fetchUrl;
    if (selectedRequest) {
      fetchUrl = `https://rfidpartnership.com/api/documents/?request_id=${selectedRequest}`;
    } else {
      fetchUrl = `https://rfidpartnership.com/api/documents/?username=${user.username}`;
    }

    fetch(fetchUrl)
      .then((response) => response.json())
      .then((data) => {
        setData(data.documents);
        if (onFetchTargetItems) {
          onFetchTargetItems(data.documents.map((doc) => doc.rfid));
        }
      })
      .catch((err) => console.error(err));
  }, [onFetchTargetItems, user, selectedRequest]);

  useEffect(() => {
    const normalizedCurrentItems = currentItems.map((item) =>
      item.trim().toLowerCase()
    );

    const redDocs = data.filter(
      (doc) =>
        location.pathname === "/scan-room" &&
        !normalizedCurrentItems.includes(doc.rfid_tag_id.trim().toLowerCase())
    );
    setRedHighlightedDocs(redDocs);
  }, [data, currentItems, location.pathname]);

  const filteredData = data.filter(
    (doc) =>
      doc.rfid_tag_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.nama_dokumen.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log("Filtered data:", filteredData); // Logging filtered data

  // Handle download PDF
  const handleDownloadPDF = () => {
    const pdf = new jsPDF();
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    // Add header
    pdf.setFont("helvetica", "bold"); // Set font to bold
    pdf.setFontSize(16);

    // Define the header text
    const headerText = "BERITA ACARA KEHILANGAN DOKUMEN";

    // Calculate the width of the text to center it
    const textWidth = pdf.getTextWidth(headerText);
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Calculate x position to center the text
    const xPosition = (pageWidth - textWidth) / 2;
    const yPosition = 10; // Y position for the text

    // Add the text to PDF
    pdf.text(headerText, xPosition, yPosition);

    // Underline the text
    pdf.line(xPosition, yPosition + 2, xPosition + textWidth, yPosition + 2); // Draw underline line

    // Add metadata
    pdf.setFont("helvetica", "normal"); // Set font to normal
    pdf.setFontSize(12);

    const metadataText = `BERITA ACARA KEHILANGAN DOKUMEN Ini dibuat pada ${formatIndonesianDate(
      new Date()
    )} oleh ${
      user.username
    }. Dengan ini menyatakan bahwa pada hasil scan-room telah kehilangan dokumen sebagai berikut:`;

    // Split the text into lines
    const lines = pdf.splitTextToSize(metadataText, 180); // Adjust 180 to fit your page width

    // Add the text to PDF
    pdf.text(lines, 10, 20);

    // Prepare data for table
    const tableData = redHighlightedDocs.map((document, index) => [
      index + 1,
      document.nama_dokumen,
      document.room,
      document.rack,
      document.row,
      document.box,
      document.rfid_tag_id,
    ]);

    console.log("redHighlightedDocs", redHighlightedDocs);

    pdf.autoTable({
      startY: 40,
      head: [["No.", "Nama Dokumen", "Room", "Rack", "Row", "Box", "RFID"]],
      body: tableData,
    });
    // Add closing statement
    pdf.text(
      "Demikian Berita Acara ini dibuat dengan sebenar - benarnya.",
      10,
      pdf.autoTable.previous.finalY + 10
    );

    pdf.text(
      `${user.username}, ${formatIndonesianDateWithoutDay(new Date())}`,
      10,
      pdf.autoTable.previous.finalY + 20
    );

    // Save the PDF
    pdf.save(`berita-kehilangan-${formattedDate}.pdf`);
  };

  return (
    <div>
      <table id="databaseTable">
        <thead>
          <tr>
            <th colSpan="2">
              <div className="uppercase">{user.branch_name}</div>
            </th>
          </tr>
          <tr id="tb-header">
            <th colSpan="1">Total Dokumen: {filteredData.length}</th>
            {location.pathname === "/scan-room" && (
              <>
                <div style={{ textAlign: "left" }}>
                  <button
                    className="modal-button edit"
                    onClick={handleDownloadPDF}
                    style={{ maxWidth: "150px", padding: "5px 10px" }}
                  >
                    Export PDF Berita Kehilangan
                  </button>
                </div>
              </>
            )}
          </tr>
          <tr id="tb-header">
            <th>RFID</th>
            <th>Nama Dokumen</th>
          </tr>
        </thead>
        <tbody id="databaseTableBody">
          {filteredData.map((doc, index) => {
            const isRedHighlighted = redHighlightedDocs.some(
              (redDoc) => redDoc.rfid_tag_id === doc.rfid_tag_id
            );

            return (
              <tr
                key={index}
                style={{
                  color: isRedHighlighted ? "#d32f2f" : "",
                }}
                onClick={() => onRowClick(doc.rfid_tag_id)}
              >
                <td>{doc.rfid_tag_id}</td>
                <td>{doc.nama_dokumen}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DBFetcherUser;
