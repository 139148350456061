import React, { useEffect } from "react";
import "./NavBar.css"; // Import the CSS file

function NavBar({ theme, setTheme }) {
  const handleMenuBarClick = () => {
    const sideBar = document.querySelector(".sidebar");
    if (sideBar) {
      sideBar.classList.toggle("close");
    }
  };

  // Effect to apply theme-based styles to the body
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.toggle("dark", theme === "dark");
      body.classList.toggle("light", theme === "light");
    }
  }, [theme]);

  return (
    <div className="content">
      <nav className="navbar-gradient">
        <i className="bx bx-menu" style={{color:"#fff"}} onClick={handleMenuBarClick}></i>
        <div id="navbar-title">
          <h2 style={{ color:'var(--sidebar-bg)', fontWeight:'bolder', marginRight:'10px'}}>bjb</h2>
          <p style={{color:'var(--sidebar-bg)',display:'inline'}}>Document Tracker</p>
          
        </div>
        <input
            type="checkbox"
            id="theme-toggle"
            hidden
            checked={theme === "dark"} // Sync with current theme state
            onChange={() => setTheme(theme === "light" ? "dark" : "light")}
          />
          <label htmlFor="theme-toggle" className="theme-toggle"></label>
      </nav>
    </div>
  );
}

export default NavBar;