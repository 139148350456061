import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DBFetcher = ({
  onFetchTargetItems,
  currentItems = [],
  searchQuery = "",
  onRowClick,
  user,
  branch,
  selectedRequest,
}) => {
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // Determine the fetch URL based on whether the selectedRequest is provided
    let fetchUrl;
    if (selectedRequest) {
      fetchUrl = `https://rfidpartnership.com/api/documents/?request_id=${selectedRequest}`;
    } else {
      fetchUrl =
        user.type === "cabang" || !branch
          ? `https://rfidpartnership.com/api/documents/?username=${user.username}`
          : `https://rfidpartnership.com/api/documents/?username=${branch.username}`;
    }

    console.log("Branch:", branch);
    console.log("User:", user);
    console.log("Fetch url:", fetchUrl);

    fetch(fetchUrl)
      .then((response) => response.json())
      .then((data) => {
        setData(data.documents);
        if (onFetchTargetItems) {
          onFetchTargetItems(data.documents.map((doc) => doc.rfid_tag_id));
        }
      })
      .catch((err) => console.error(err));
  }, [onFetchTargetItems, user, branch, selectedRequest]);

  if (!data.length && user.type !== "cabang" && !branch) {
    return "Please select a branch";
  } else if (!data.length && user.type === "cabang") {
    return "No data available for this branch";
  }

  // Normalize the currentItems for comparison
  const normalizedCurrentItems = currentItems.map((item) =>
    item.trim().toLowerCase()
  );

  // Filter data based on searchQuery
  const filteredData = data.filter(
    (doc) =>
      doc.rfid_tag_id
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.document_id
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kode_pelaksana
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.user_id
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kode_klas
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.unit_pengolahan
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.tanggal_dokumen
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.no_dokumen
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.perihal
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.nama_dokumen
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.tanggal_dokumen_lunas
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kurun_waktu_awal
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kurun_waktu_akhir
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.tp.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.media_simpan
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.kondisi
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.jumlah_berkas
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.masa_retensi
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.status.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.rfid_tag_id
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      doc.room.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.rack.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.row.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.box.toString().toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or empty

    // Ensure dateString is a string and then proceed with replace method
    if (typeof dateString !== "string") {
      dateString = dateString.toString();
    }

    return dateString.replace("T", " ").replace("Z", "").split(".")[0];
  };

  return (
    <div>
      <table id="databaseTable">
        <thead>
          <tr>
            <th colSpan="2">
              <div className="uppercase">
                {branch && branch.branch_name
                  ? branch.branch_name
                  : user.branch_name}
              </div>
            </th>
          </tr>
          <tr>
            <th colSpan="2">Total Dokumen: {filteredData.length}</th>
          </tr>
          <tr id="tb-header">
            <th>ID Dokumen</th>
            <th>Kode Pelaksana</th>
            <th>ID User</th>
            <th>Kode Klas</th>
            <th>Unit Pengolahan</th>
            <th>Tanggal Dokumen</th>
            <th>No Dokumen</th>
            <th>Perihal</th>
            <th>Nama Dokumen</th>
            <th>Tanggal Dokumen Lunas</th>
            <th>Kurun Waktu Awal</th>
            <th>Kurun Waktu Akhir</th>
            <th>TP</th>
            <th>Media Simpan</th>
            <th>Kondisi</th>
            <th>Jumlah Berkas</th>
            <th>Masa Retensi</th>
            <th>Status</th>
            <th>RFID Tag ID</th>
            <th>Room</th>
            <th>Rack</th>
            <th>Row</th>
            <th>Box</th>
          </tr>
        </thead>
        <tbody id="databaseTableBody">
          {filteredData.map((doc, index) => (
            <tr
              key={index}
              style={{
                background:"var(--light)",
                color:
                  location.pathname === "/scan-room" &&
                  !normalizedCurrentItems.includes(
                    doc.rfid_tag_id.trim().toLowerCase()
                  )
                    ? "#d32f2f"
                    : "",
              }}
              onClick={() => onRowClick(doc.rfid_tag_id)}
            >
              <td>{doc.document_id}</td>
              <td>{doc.kode_pelaksana}</td>
              <td>{doc.user_id}</td>
              <td>{doc.kode_klas}</td>
              <td>{doc.unit_pengolahan}</td>
              <td>{formatDate(doc.tanggal_dokumen)}</td>
              <td>{doc.no_dokumen}</td>
              <td>{doc.perihal}</td>
              <td>{doc.nama_dokumen}</td>
              <td>{formatDate(doc.tanggal_dokumen_lunas)}</td>
              <td>{formatDate(doc.kurun_waktu_awal)}</td>
              <td>{formatDate(doc.kurun_waktu_akhir)}</td>
              <td>{doc.tp}</td>
              <td>{doc.media_simpan}</td>
              <td>{doc.kondisi}</td>
              <td>{doc.jumlah_berkas}</td>
              <td>{formatDate(doc.masa_retensi)}</td>
              <td>{doc.status}</td>
              <td>{doc.rfid_tag_id}</td>
              <td>{doc.room}</td>
              <td>{doc.rack}</td>
              <td>{doc.row}</td>
              <td>{doc.box}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DBFetcher;
