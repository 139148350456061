import React, { useEffect, useState } from "react";
import axios from "axios";

const DataFetcher = ({ returnType, refreshKey, selectedUser, user }) => {
  const [data, setData] = useState(null);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  console.log("user data Fecther: ", user);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.user_id) {
        console.error("User information is missing.");
        setError("User information is missing.");
        setContent(0); // Set content to 0 in case of error
        return;
      }

      try {
        const response = await axios.get(`https://rfidpartnership.com/api/data/${user.user_id}`);

        if (!response.data || typeof response.data.data !== "string") {
          throw new Error("Fetched data is empty or not in expected format");
        }

        console.log("Fetched data:", response.data.data);

        setData(response.data.data); // Set data as the string value

        switch (returnType) {
          case "count":
            setContent(getRFIDDataCount(response.data.data)); // Pass the string to getRFIDDataCount
            break;
          case "table":
            setContent(getRFIDDataTable(response.data.data)); // Pass the string to getRFIDDataTable
            break;
          case "raw":
            setContent(response.data.data); // Set content as the raw data string
            break;
          case "upload":
            setContent(getRFIDDataTableBtn(response.data.data, selectedUser)); // Pass the string to getRFIDDataTableBtn
            break;
          default:
            setContent(null);
            break;
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError(error.message);
        setContent(0); // Set content to 0 in case of error
      }
    };

    fetchData();
  }, [returnType, refreshKey, user]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (content === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {returnType === "count" ? (
        <p>Total Dokumen: {content}</p>
      ) : returnType === "table" ? (
        content
      ) : returnType === "raw" ? (
        <pre>{content}</pre>
      ) : returnType === "upload" ? (
        content
      ) : (
        <p>Invalid return type specified.</p>
      )}
    </div>
  );
};

export function getRFIDDataCount(data) {
  if (!data) {
    return 0;
  }
  return data.split(",").filter((item) => item.trim() !== "").length;
}

export function getRFIDDataTable(data) {
  if (!data) {
    return (
      <table id="databaseTable">
        <thead>
          <tr>
            <th>RFID</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody id="databaseTableBody">
          <tr>
            <td colSpan="2">No data available</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <table id="databaseTable">
      <thead>
        <tr>
          <th>RFID</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody id="databaseTableBody">
        {data
          .split(",")
          .filter((item) => item.trim() !== "")
          .map((item, index) => (
            <tr key={index}>
              <td>{item}</td>
              <td>{index}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export function getRFIDDataTableBtn(data, selectedUser) {
  const handleAddRFIDs = async (e) => {
    e.preventDefault();
    const rfids = data.split(",").filter((item) => item.trim() !== "");

    console.log("Selected user for adding RFIDs:", selectedUser);

    try {
      await axios.post(`https://rfidpartnership.com/api/allowed_rfids/${selectedUser}`, {
        rfids,
      });
      console.log("RFIDs added successfully");
      alert("RFIDs added successfully. Please refresh.");
    } catch (error) {
      console.error("Error adding RFIDs:", error);
      alert(`Error adding RFIDs: ${error.message}`);
    }
  };

  if (!data) {
    return (
      <div>
        <table id="databaseTable">
          <thead>
            <tr>
              <th>RFID</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody id="databaseTableBody">
            <tr>
              <td colSpan="2">No data available</td>
            </tr>
          </tbody>
        </table>
        <button onClick={handleAddRFIDs}>Add RFIDs</button>
      </div>
    );
  }
  return (
    <div>
      <table id="databaseTable">
        <thead>
          <tr>
            <th>RFID</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody id="databaseTableBody">
          {data
            .split(",")
            .filter((item) => item.trim() !== "")
            .map((item, index) => (
              <tr key={index}>
                <td>{item}</td>
                <td>{index + 1}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <button onClick={handleAddRFIDs}>Add RFIDs</button>
    </div>
  );
}

export default DataFetcher;
