import React, { useState, useEffect } from "react";
import axios from "axios";
import RequestFetcher from "./requestFetcher";
import OutgoingFetcher from "./outgoingFetcher";

function Request({ user }) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [incomingSearchQuery, setIncomingSearchQuery] = useState("");
  const [outgoingSearchQuery, setOutgoingSearchQuery] = useState("");
  const [documents, setDocuments] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedOutgoingRequest, setSelectedOutgoingRequest] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [branchOptions, setBranchOptions] = useState([]);

  useEffect(() => {
    console.log("User id:", user.user_id);

    axios
      .get(`https://rfidpartnership.com/api/branch_for_depo/?depo_id=${user.user_id}`)
      .then((response) => {
        setBranchOptions(
          response.data.documents.map((doc) => ({
            user_id: doc.user_id,
            username: doc.username,
            branch_name: doc.branch_name,
          }))
        );
      })
      .catch((err) => console.error(err));

    console.log(user);
  }, [user.user_id]);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const showModal = (modalType) => {
    if (modalType === "add") {
      setShowAddModal(true);
    } else if (modalType === "edit") {
      setShowEditModal(true);
    }
  };

  const closeModal = () => {
    setShowAddModal(false);
    setShowEditModal(false);
  };

  const handleOutgoingRowClick = (request) => {
    setSelectedOutgoingRequest(request);

    // Check if request.rfid is already in selectedDocuments
    const isRfidSelected = selectedDocuments.some(
      (doc) => doc === request.rfid_tag_id
    );

    if (isRfidSelected) {
      // If rfid already selected, remove it from selectedDocuments
      setSelectedDocuments((prevSelectedDocuments) =>
        prevSelectedDocuments.filter((doc) => doc !== request.rfid_tag_id)
      );
    } else {
      // Otherwise, add it to selectedDocuments
      setSelectedDocuments((prevSelectedDocuments) => [
        ...prevSelectedDocuments,
        request.rfid_tag_id,
      ]);
    }

    console.log("Selected Outgoing Request:", request);
  };

  const handleIncomingRowClick = (request) => {
    setSelectedRequest(request);
    showModal("edit");
  };

  const handleOutgoingSearchChange = (event) => {
    setOutgoingSearchQuery(event.target.value);
  };

  const handleIncomingSearchChange = (event) => {
    setIncomingSearchQuery(event.target.value);
  };

  const handleConfirmSubmit = () => {
    if (user.type === "depo" && !selectedOption) {
      // Prevent submission if branch is not selected and user type is depo
      console.error("Branch not selected");
      return;
    }

    console.log("Handle confirm:", selectedDocuments);

    if (selectedDocuments.length > 0) {
      console.log("Selected Documents after handle:", selectedDocuments);

      const requestData = {
        user_id: user.user_id,
        username: user.username,
        rfid_tag_id: selectedDocuments.map((doc) => doc),
        userType: user.type,
        selectedBranchId: user.type === "depo" ? selectedOption : null,
      };

      console.log("Request Data:", requestData);
      alert("Request successfully sent. Wait for approval.");

      fetch("https://rfidpartnership.com/api/request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send request to depo");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Request successfully sent to depo:", data);
        })
        .catch((error) => {
          console.error("Error sending request to depo:", error.message);
          // Error handling logic
        });
    } else {
      console.error("Selected request or documents are invalid");
      // Logic to notify user or handle this case
    }
    closeModal();
  };

  const handleDenyRequest = (event) => {
    event.preventDefault();
    if (selectedRequest && selectedRequest.request_id) {
      console.log("Denying request:", selectedRequest.request_id);
      fetch(
        `https://rfidpartnership.com/api/deny_request/?request_id=${selectedRequest.request_id}`,
        {
          method: "POST",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to deny request");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Request successfully denied:", data);
        })
        .catch((error) => {
          console.error("Error denying request:", error.message);
        });

      closeModal();
    } else {
      console.error("No request selected to deny");
    }
  };

  const handleApproveRequest = () => {
    if (selectedRequest && selectedRequest.request_id) {
      console.log("Approving request:", selectedRequest.request_id);
      fetch(
        `https://rfidpartnership.com/api/approve_request/?request_id=${selectedRequest.request_id}&user_id=${user.user_id}`,
        {
          method: "POST",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to approve request");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Request successfully approved:", data);
        })
        .catch((error) => {
          console.error("Error approving request:", error.message);
        });

      closeModal();
    } else {
      console.error("No request selected to approve");
    }
  };

  useEffect(() => {
    if (selectedRequest) {
      fetch(
        `https://rfidpartnership.com/api/documents_from_user/?request_id=${selectedRequest.request_id}`
      )
        .then((response) => response.json())
        .then((data) => {
          setDocuments(data.documents);
        })
        .catch((err) => console.error(err));
    }
  }, [selectedRequest]);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or empty

    // Ensure dateString is a string and then proceed with replace method
    if (typeof dateString !== "string") {
      dateString = dateString.toString();
    }

    return dateString.replace("T", " ").replace("Z", "").split(".")[0];
  };

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Requests</h1>
          </div>
        </div>

        <div className="bottom-data" style={{ flexDirection: "column" }}>
          <div className="database-view">
            <div className="header">
              <h3>Outgoing Requests</h3>
              <div className="header-container">
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={outgoingSearchQuery}
                  onChange={handleOutgoingSearchChange}
                />
                <i className="bx bx-send" onClick={() => showModal("add")}></i>
              </div>
            </div>
            <OutgoingFetcher
              returnType="table"
              searchQuery={outgoingSearchQuery}
              onRowClick={handleOutgoingRowClick}
              user={user}
            />
          </div>
          <div className="database-view">
            <div className="header">
              <h3>Incoming Requests</h3>
              <div className="header-container">
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={incomingSearchQuery}
                  onChange={handleIncomingSearchChange}
                />
              </div>
            </div>
            <RequestFetcher
              returnType="table"
              onRowClick={handleIncomingRowClick}
              searchQuery={incomingSearchQuery}
              user={user}
            />
          </div>
        </div>
      </main>

      {/* Confirmation Modal */}
      <div className={`modal ${showAddModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>Confirmation</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="confirmation">
              <div>
                <label htmlFor="confirm-label" style={{ fontSize: "20px" }}>
                  Confirm and send?
                </label>
                {user.type !== "cabang" && (
                  <select
                    className="search-bar uppercase"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    style={{ marginTop: "16px" }}
                  >
                    <option value="" disabled>
                      Pilih cabang yang dituju
                    </option>
                    {branchOptions.map((option) => (
                      <option
                        key={option.user_id}
                        value={option.user_id}
                        className="uppercase"
                      >
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                )}
                <div
                  className="modal-content-buttons"
                  style={{ marginTop: "8px" }}
                >
                  <button
                    className="modal-button edit"
                    type="submit"
                    onClick={handleConfirmSubmit}
                    disabled={user.type === "depo" && !selectedOption} // Disable button if user is depo and no branch selected
                    style={{
                      cursor:
                        user.type === "depo" && !selectedOption
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    className="modal-button delete"
                    type="button"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Edit Form Modal */}
      <div className={`modal ${showEditModal ? "open" : ""}`}>
        <div className="modal-content" style={{ maxWidth: "700px" }}>
          <div className="modal-content-header">
            <h2>Request Details</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="documents-requested">
              {selectedRequest && (
                <>
                  <table
                    id="databaseTable"
                    style={{ width: "100%", borderCollapse: "collapse" }}
                  >
                    <thead>
                      <tr>
                        <td>
                          <strong>Request ID</strong>
                        </td>
                        <td>: {selectedRequest.request_id}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Dari</strong>
                        </td>
                        <td className="uppercase">
                          : {selectedRequest.from_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Ke</strong>
                        </td>
                        <td className="uppercase">
                          : {selectedRequest.to_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Tanggal Dibuat</strong>
                        </td>
                        <td>: {formatDate(selectedRequest.created_at)}</td>
                      </tr>
                      <tr>
                        <th colSpan={2}>
                          <br />
                          <hr />
                          <br />
                        </th>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                          RFID
                        </th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                          Nama Dokumen
                        </th>
                      </tr>
                    </thead>
                    <tbody id="databaseTableBody">
                      {documents.map((document, index) => (
                        <tr key={index}>
                          <td style={{ padding: "10px" }}>{document.rfid}</td>
                          <td style={{ padding: "10px" }}>
                            {document.nama_dokumen}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}

              <div className="modal-content-buttons">
                <button
                  className="modal-button edit"
                  type="submit"
                  onClick={handleApproveRequest}
                >
                  Approve
                </button>
                <button
                  className="modal-button delete"
                  type="submit"
                  onClick={handleDenyRequest}
                >
                  Deny
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Request;
