import React, { useState, useEffect } from "react";
import axios from "axios";
import DBFetcherUser from "./databaseFetcherUser";
import CombinedFetcher from "./mergeFetcher";

function ScanRoom({ user }) {
  const [targetItems, setTargetItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [requestOptions, setRequestOptions] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState("");

  // Fetch request options when user ID changes
  useEffect(() => {
    fetchRequestOptions();
  }, [user.user_id]);

  const fetchRequestOptions = () => {
    axios
      .get(
        `https://rfidpartnership.com/api/request_for_depo/?depo_id=${user.user_id}`
      )
      .then((response) => {
        setRequestOptions(
          response.data.documents.map((doc) => ({
            request_id: doc.request_id,
            from_user_id: doc.from_user_id,
            to_user_id: doc.to_user_id,
            status: doc.status,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedRequest(event.target.value);
  };

  const handleCombinedDataFetch = (combinedRFIDs) => {
    setCurrentItems(combinedRFIDs);
  };

  const refreshData = () => {
    setRefreshKey((prevKey) => prevKey + 1);
    setTargetItems([]);
    setCurrentItems([]);
    setSelectedRequest("");
    fetchRequestOptions();
  };

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Scan Room</h1>
          </div>
          <div className="right">
            <button className="refresh-button" onClick={refreshData}>
              Refresh <i className="bx bx-refresh"></i>
            </button>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3 id="page-title">Target Items</h3>
              {user.type !== "cabang" && (
                <select
                  className="search-bar uppercase"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="">Current Depo</option>
                  {requestOptions.map((option) => (
                    <option
                      key={option.request_id}
                      value={option.request_id}
                      className="uppercase"
                    >
                      Request ID: {option.request_id}
                    </option>
                  ))}
                </select>
              )}
            </div>

            <DBFetcherUser
              key={`db-fetcher-${refreshKey}`}
              returnType="table"
              onFetchTargetItems={setTargetItems}
              currentItems={currentItems}
              onRowClick={() => {}}
              user={user}
              selectedRequest={selectedRequest}
            />
          </div>

          <div className="database-view">
            <div className="header">
              <h3 id="page-title">Current Items</h3>
            </div>
            {refreshKey == 0 && (
              <p>Waiting for android send data...</p>
            )}
            {/* CombinedFetcher will only fetch data after refreshKey changes */}
            {refreshKey > 0 && (
              <CombinedFetcher
                key={`combined-fetcher-${refreshKey}`}
                onFetchCombinedData={handleCombinedDataFetch}
                username={user.username}
                user_idP={user.user_id}
                refreshKey={refreshKey}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default ScanRoom;
